export default {
      application: require('./components/application/dist.index.js').default,
appbar: require('./components/appbar/dist.index.js').default,
layout: require('./components/layout/dist.index.js').default,
sidebar: require('./components/sidebar/dist.index.js').default,
flex: require('./components/flex/dist.index.js').default,
image: require('./components/image/dist.index.js').default,
text: require('./components/text/dist.index.js').default,
button: require('./components/button/dist.index.js').default,
modal: require('./components/modal/dist.index.js').default,
table: require('./components/table/dist.index.js').default
    }